import axios from './axios'
import AbstractApi from './AbstractApi'
import { POSTAL_CODE_URL } from './apiConstants'
import { getHeaders } from '../utils/functions/apiUtils'

class PostalCode extends AbstractApi {
  constructor() {
    super(POSTAL_CODE_URL)
  }

  updateActive = ({ token, obj, _id }) =>
    axios.put(`${this.url}/${_id}`, obj, getHeaders(token))

  findAllV2 = ({ token, params }) =>
    axios.get(`${this.url}/v2`, getHeaders(token, params))
}

export default new PostalCode()
