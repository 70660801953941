import { useRef } from 'react';
import { useJsApiLoader, Autocomplete } from '@react-google-maps/api';

import { GOOGLE_MAPS_API_KEY } from '../../../../utils/constants/envConstants';

import './PlaceAutocompleteInput.css'

const PlaceAutocompleteInput = ({ onChange, onPostalCodeSelect, className = '' }) => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries: ['places'],
  });

  const autocompleteRef = useRef(null);

  const handlePlaceChanged = () => {
    const place = autocompleteRef.current?.getPlace();
    if (!place || !place.address_components) return;


    const postalCode = place.address_components.find(component =>
      component.types.includes('postal_code')
    )?.long_name;

    if (postalCode && onPostalCodeSelect) {
      onPostalCodeSelect(postalCode);
    }
  };

  return (
    <div style={{ width: '100%' }}>
      {isLoaded && (
        <Autocomplete
          onLoad={(instance) => (autocompleteRef.current = instance)}
          restrictions={{ country: ['ca', 'us'] }}
          fields={[
            'formatted_address',
            'address_components',
            'name',
            'place_id',
            'url',
          ]}
          onPlaceChanged={handlePlaceChanged}
        >
          <input
            className={`place-autocomplete-input ${className}`}
          />
        </Autocomplete>
      )}
    </div>
  )
};

export default PlaceAutocompleteInput;
