import axios from './axios'
import AbstractApi from './AbstractApi'
import { CALENDAR } from './apiConstants'
import { getHeaders } from '../utils/functions/apiUtils'

class Calendar extends AbstractApi {
  constructor() {
    super(CALENDAR)
  }

  byPostalCode = ({ token, params }) =>
    axios.get(
      `${process.env.REACT_APP_API}${CALENDAR}/by-postal-code`,
      getHeaders(token, params),
    )

  byPostalCodeV2 = ({ token, params }) =>
    axios.get(
      `${process.env.REACT_APP_API}${CALENDAR}/v2/by-postal-code`,
      getHeaders(token, params),
    )

  findAllPickupDates = ({ token, params }) =>
    axios.get(
      `${process.env.REACT_APP_API}${CALENDAR}/pickup-date`,
      getHeaders(token, params),
    )

  findAllPickupDatesV2 = ({ token, params }) =>
    axios.get(
      `${process.env.REACT_APP_API}${CALENDAR}/v2/pickup-date`,
      getHeaders(token, params),
    )

  addPickupDates = ({ token, obj }) =>
    axios.post(
      `${process.env.REACT_APP_API}${CALENDAR}/pickup-date`,
      obj,
      getHeaders(token),
    )

  create = ({ token, obj }) =>
    axios.post(`${this.url}/create`, obj, getHeaders(token))

  update = ({ token, _id, obj }) =>
    axios.put(`${this.url}/update/${_id}`, obj, getHeaders(token))

  editRecurrentDate = ({ token, obj }) =>
    axios.post(
      `${process.env.REACT_APP_API}${CALENDAR}/pickup-date/recurrent`,
      obj,
      getHeaders(token),
    )

  findOne = ({ token, params }) =>
    axios.get(`${this.url}/pickup-date/find`, getHeaders(token, params))

  recurence = ({ token, obj }) =>
    axios.post(`${this.url}/refrence`, obj, getHeaders(token))
}

export default new Calendar()
