import React from 'react'
import logoBlack from '../../../assets/img/LogoKidneyC.png'
import { Button } from 'antd'

const CompletedStep = ({ onClick }) => {
  return (
    <div className='d-flex justify-content-center align-items-center'>
      <div
        className='d-flex justify-content-center align-items-center'
        style={{
          flexDirection: 'column',
          boxShadow: '0 .125rem .25rem rgba(0, 0, 0, .075)',
          borderRadius: '8px',
          padding: '24px',
          border: '1px solid #d9d9d9',
          width: '500px',
        }}
      >
        <img src={logoBlack} alt='App Logo' width='200' />
        <p className='mt-4' style={{ fontSize: '22px', fontWeight: '600' }}>
          Successfully submitted request!
        </p>
        <Button type='primary' htmlType='submit' onClick={onClick}>
          Done
        </Button>
      </div>
    </div>
  )
}

export default CompletedStep
