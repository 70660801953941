import { Card, Form, Input, Space } from 'antd'
import {
  EnvironmentOutlined,
  CalendarOutlined,
  ClockCircleOutlined,
} from '@ant-design/icons'
import PhoneInput from 'antd-phone-input'

import { useIntl } from 'react-intl'
import { formatDate } from '../../../utils/functions/dayjsFunction'

const { TextArea } = Input
const { Item } = Form

const ContactInformationStep = ({ values }) => {
  const { formatMessage } = useIntl()
  return (
    <>
      <div className='text-center'>
        <div className='mb-4'>
          <h1 className='m-0 p-0 mb-2' style={{ color: '#002F5E' }}>
            Contact Information
          </h1>
          <div
            style={{
              borderTop: '2px solid #E8256B',
              width: '38px',
              margin: 'auto',
            }}
          />
        </div>
        <h2>Enter you contact information</h2>
        <p>
          Household collection is limited due to Covid protocols. Enter your
          address to see if we have resumed collection in your area. If there is
          no date available please use a donation bin
        </p>
      </div>
      <div>
        <div className='row'>
          <div className='col-6'>
            <Item
              name='firstName'
              label={formatMessage({ id: 'firstName' })}
              rules={[
                {
                  required: true,
                },
                {
                  pattern: /^[a-zA-Z]+(?:\s[a-zA-Z]+)?$/,
                  message: 'Accepts Letters',
                },
              ]}
              className='form-item--label-hidden'
            >
              <Input size='large' maxLength={20} placeholder='First Name' />
            </Item>
          </div>
          <div className='col-6'>
            <Item
              name='lastName'
              label={formatMessage({ id: 'lastName' })}
              rules={[
                { required: true },
                {
                  pattern: /^[a-zA-Z]+(?:\s[a-zA-Z]+)?$/,
                  message: 'Accepts Letters',
                },
              ]}
              className='form-item--label-hidden'
            >
              <Input size='large' maxLength={20} placeholder='Last Name' />
            </Item>
          </div>
        </div>
        <div className='row'>
          <div className='col-6'>
            <Item
              name='email'
              label={formatMessage({ id: 'email' })}
              rules={[{ required: true }, { type: 'email' }]}
              className='form-item--label-hidden'
            >
              <Input size='large' placeholder='Email Address' />
            </Item>
          </div>
          <div className='col-6'>
            <Item
              name='phone'
              label={formatMessage({ id: 'phone' })}
              rules={[
                { required: true },
              ]}
              className='form-item--label-hidden'
              normalize={(value) => {
                return `+${value.countryCode}${value.areaCode}${value.phoneNumber}`
              }}
            >
              <PhoneInput
                size='large'
                placeholder='Phone Number'
                enableSearch
                disableParentheses
                distinct
              />
            </Item>
          </div>
        </div>
        <div className='row'>
          <div className='col-6'>
            <Item
              name={['address', 'city']}
              label={formatMessage({ id: 'city' })}
              rules={[{ required: true, max: 25 }]}
              className='form-item--label-hidden'
            >
              <Input size='large' maxLength={25} placeholder='City' />
            </Item>
          </div>
          <div className='col-6'>
            <Item
              name={['address', 'province']}
              label={formatMessage({ id: 'province' })}
              rules={[
                { required: true },
                {
                  pattern: /^[a-zA-Z]+(?:\s[a-zA-Z]+)?$/,
                  message: 'Accepts Letters',
                },
              ]}
              className='form-item--label-hidden'
            >
              <Input size='large' maxLength={25} placeholder='Province' />
            </Item>
          </div>
        </div>
        <div>
          <Item
            name={['address', 'street']}
            label={formatMessage({ id: 'address' })}
            rules={[{ required: true }]}
            className='form-item--label-hidden'
          >
            <Input size='large' placeholder='Street Address' />
          </Item>
        </div>
        <div>
          <Item
            noStyle
            shouldUpdate={(prevValues, currValues) =>
              prevValues.address?.postalCode !== currValues.address?.postalCode
            }
          >
            {({ getFieldValue, setFieldsValue }) => {
              const pattern = /[A-Za-z]\d[A-Za-z] ?\d[A-Za-z]\d/
              const value = getFieldValue(['address', 'postalCode'])
              let match = pattern.exec(value)
              if (match && !value.includes(' '))
                setFieldsValue({
                  address: {
                    postalCode: `${value.slice(0, 3)} ${value.slice(3, 6)}`,
                  },
                })

              return (
                <Item
                  name={['address', 'postalCode']}
                  label={formatMessage({ id: 'postalCode' })}
                  rules={[
                    { required: true },
                    {
                      pattern,
                      message: 'Incorrect Password',
                    },
                  ]}
                  className='form-item--label-hidden'
                >
                  <Input size='large' placeholder='Placeholder' maxLength={7} />
                </Item>
              )
            }}
          </Item>
        </div>
        <div>
          <Item name='comment'>
            <TextArea size='large' maxLength={100} placeholder='Notes' />
          </Item>
        </div>
      </div>
      <div>
        <div>
          <h2 className='mb-4'>About your pick up</h2>
        </div>
        <Card>
          <h3>LOCATION AND DATE</h3>
          <Item
            noStyle
            shouldUpdate={(prev, curr) =>
              prev.address?.street !== curr.address?.street
            }
          >
            {({ getFieldValue }) => (
              <>
                <div className='mb-2'>
                  <Space>
                    <CalendarOutlined />
                    {formatDate(
                      values.step1?.pickup?.pickupDate,
                      'ddd, MMMM Do',
                    )}
                  </Space>
                </div>
                <div className='mb-2'>
                  <Space>
                    <ClockCircleOutlined />
                    <span>8 a.m. to 6 p.m.</span>
                  </Space>
                </div>
                {getFieldValue(['address', 'city']) && (
                  <div className='mb-2'>
                    <Space>
                      <EnvironmentOutlined />
                      <span>{getFieldValue(['address', 'street'])}</span>
                    </Space>
                  </div>
                )}
              </>
            )}
          </Item>
        </Card>
      </div>
    </>
  )
}

export default ContactInformationStep
