import { Card, Button, Radio, Form, Spin, Space } from 'antd'

import { errorNotification } from '../../../utils/functions/actionNotifications'

import './LocationAndDateStep.css'
import {
  formatDate,
  formatDateToday,
} from '../../../utils/functions/dayjsFunction'
import PlaceAutocompleteInput from './PlaceAutocompleteInput/PlaceAutocompleteInput'
import { useState } from 'react'

const { Item } = Form
const { Group } = Radio

const LocationAndDateStep = ({ form, pickups, loading, onSearch }) => {

  const [postalCode, setPostalCode] = useState();

  const handleSearch = (value) => {
    if (!value) {
      errorNotification({ message: 'PostalCode not found.' })
      return
    }
    form.setFieldsValue({ address: { postalCode: value } })
    setPostalCode(value)
    onSearch({
      params: {
        postalCode: value,
        month: formatDateToday('MM'),
      },
    })
  }

  const refetchSearch = () => {
    if (!postalCode) {
      errorNotification({ message: 'PostalCode not found.' })
      return
    }
    form.setFieldsValue({ address: { postalCode } })
    onSearch({
      params: {
        postalCode,
        month: formatDateToday('MM'),
      },
    })
  }

  return (
    <>
      <div className='text-center'>
        <div className='mb-4'>
          <h1 className='m-0 p-0 mb-2' style={{ color: '#002F5E' }}>
            Location and Date
          </h1>
          <div
            style={{
              borderTop: '2px solid #E8256B',
              width: '38px',
              margin: 'auto',
            }}
          />
        </div>
        <h2>Where can we pick up your clothing donation?</h2>
        <p>
          Household collection is limited due to Covid protocols. Enter your
          address to see if we have resumed collection in your area. If there is
          no date available please use a donation bin
        </p>
      </div>
      <div className='my-5'>
        <Space.Compact style={{ width: '100%' }}>
          <PlaceAutocompleteInput onPostalCodeSelect={handleSearch} />
          <Button size='large' type='primary' onClick={refetchSearch}>
            Search
          </Button>
        </Space.Compact>
      </div>
      <div>
        <div>
          <h2>Please select a date that works best for you</h2>
          <p>All of our pick ups are between 8 a.m. and 6 p.m.</p>
        </div>
        <div>
          <Spin spinning={loading}>
            <div className='container'>
              {pickups.length ? (
                <Item name='pickupDateId' noStyle>
                  <Group buttonStyle='solid' className='w-100'>
                    <div className='wrapper'>
                      {pickups?.map((item) => (
                        <Card key={item._id}>
                          <h4>{formatDate(item.pickupDate, 'ddd, MMMM Do')}</h4>
                          <p>8 a.m. to 6 p.m.</p>
                          <Radio.Button
                            className='w-100 text-center'
                            value={item._id}
                          >
                            Choose
                          </Radio.Button>
                        </Card>
                      ))}
                    </div>
                  </Group>
                </Item>
              ) : (
                <div className='wrapper'>
                  <Card>
                    <h4>Fri, September 17th</h4>
                    <p>8 a.m. to 6 p.m.</p>
                    <Button disabled style={{ width: '100%' }}>
                      Choose
                    </Button>
                  </Card>
                </div>
              )}
            </div>
          </Spin>
        </div>
      </div>
    </>
  )
}

export default LocationAndDateStep
